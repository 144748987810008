<template>
  <v-card class="about">
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <Row title="Authenticated As" :text="userName" />
          <Row title="Partner" :code="partnerCode" />
          <Row title="Depot" :text="depotName" v-if="depotName" />
          <Row title="Depot" v-else ><i>Not set.</i></Row>
          <Row title="Communication Channel ID" :code="pusherChannelId" />
          <Row title="Currency" :text="currency" />

          <Row title="Version" :code="version" />

          <Row title="Customers to Cache" :number="toCache" />
          <Row title="Customers Cached" >
            {{ cachedCount }}
            <v-icon dense small @click='reloadCustomers'>mdi-refresh</v-icon>
          </Row>

          <Row title="Changes not uploaded" :number="queueDepth || 0" />

          <Row title="Space Usage" :text="storage" v-if="storage" />
          <Row title="Space Usage" text="Calculating..." v-else />

          <Row title="Production?" :text="prod" />
          <Row title="Host" :code="host" />

          <Row title="Logo">
            <Logo />
          </Row>

          <Row title="Colours" >
            <Colours />
          </Row>


          <Row title="Most Recent Error" v-if="errorDetail" >
            <div>
              <code>{{errorDetail}}</code>
            </div>
          </Row>
          <Row title="Traces" v-if="errorTrace.length>0" >
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      At
                    </th>
                    <th class="text-left">
                      Message
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in errorTrace" :key="item.at" >
                    <td><Time :time="item.at"/></td>
                    <td>{{ item.info }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </Row>

        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
  import BackgroundSyncStatus from '@/store/BackgroundSyncStatus';
  import Row from '@/components/Row';
  import Colours from '@/components/Colours';
  import Logo from '@/components/Logo';
  import Time from '@/components/Time';

  export default {
    components: { Row, Colours, Logo, Time },
    data: () => ({
      queueDepth: 0,
      storage: undefined,
    }),
    computed: {
      userName() { return this.$store.getters.settings.name; },
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      currency() { return this.$store.getters.currency; },
      version() { return this.$store.getters.appVersion; },
      prod() { return (process.env.NODE_ENV === 'production' ? 'Production' : 'Development'); },
      toCache() { return this.$store.getters.customersToCacheCount; },
      cachedCount() { return (this.$store.getters.customersCachedCount || 0); },
      pusherChannelId() { return this.$store.getters.settings.fieldAgentId; },
      depotId() { return this.$store.getters.settings.fieldAgentDepotId; },
      depotName() { return ((this.$store.getters.settings.depots.filter(e => e.id==this.depotId )[0]) || {}).name },
      host() { return window.location.host; },
      errorTrace() { return (this.$store.getters.errorTrace || []); },
      errorDetail() { return this.$store.getters.errorDetail; },
    },
    methods: {
      async init() {
        BackgroundSyncStatus.depth().then(e => this.queueDepth = e);
        if ('storage' in navigator && 'estimate' in navigator.storage) {
          const {usage, quota} = await navigator.storage.estimate();
          const percentUsed = Math.round(usage / quota * 100);
          const usageInMib = Math.round(usage / (1024 * 1024));
          const quotaInMib = Math.round(quota / (1024 * 1024));
          const details = `${usageInMib} MB of ${quotaInMib} MB used (${percentUsed}%)`;
          this.storage = details;
        }
      },
      reloadCustomers() {
        this.$store.dispatch('reloadAllCustomers');
      },
    },
    mounted() {
      this.init();
      let _this = this;
      setTimeout(function() {
        if (_this.storage==null) { _this.storage = 'Unavailable'; }
      }, 3000);
    }
  }
</script>
