import { openDB } from 'idb';

export default class BackgroundSyncStatus {

  static async depth() {
    let result = 0;
    try {
      const db = await openDB('workbox-background-sync');
      result = await db.transaction('requests').objectStore('requests').count();      
    } catch(err) {
      console.log(err);
    }
    return result;
  }

}
